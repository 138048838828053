var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "order-maindiv"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mb-4"
  }, [_c('div', [_c('h6', [_vm._v("#" + _vm._s(_vm.orderItem.orderId))]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.orderItem.orderStatus === 'Created' ? _c('b-link', {
    class: _vm.orderItem.orderStatus === 'Created' ? 'xsbutton' : 'created',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderItem.orderStatus))]) : _vm._e(), _vm.orderItem.orderStatus === 'Confirmed' ? _c('b-link', {
    class: _vm.orderItem.orderStatus === 'Confirmed' ? 'delivered' : 'created',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderItem.orderStatus))]) : _vm._e(), _vm.orderItem.orderStatus === 'Cancelled' ? _c('b-link', {
    class: _vm.orderItem.orderStatus === 'Cancelled' ? 'cancelled-button' : 'xsbutton',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderItem.orderStatus))]) : _vm._e(), _vm.orderItem.orderStatus === 'Dispatched' ? _c('b-link', {
    class: _vm.orderItem.orderStatus === 'Dispatched' ? 'xs-button' : 'created',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderItem.orderStatus))]) : _vm._e(), _vm.orderItem.orderStatus === 'Delivered' ? _c('b-link', {
    class: _vm.orderItem.orderStatus === 'Delivered' ? 'delivered' : 'cancelled-button',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderItem.orderStatus))]) : _vm._e(), _vm.orderItem.orderStatus === 'Returned' ? _c('b-link', {
    class: _vm.orderItem.orderStatus === 'Returned' ? 'returned' : 'cancelled-button',
    attrs: {
      "href": "#"
    }
  }, [_vm._v(_vm._s(_vm.orderItem.orderStatus))]) : _vm._e()], 1)]), _c('div', [_vm.orderItem.type != 'IN-STORE' ? _c('p', {
    staticClass: "m-0",
    style: {
      color: '#58585b'
    }
  }, [_vm._v(" Order Total (" + _vm._s(Array.isArray(_vm.orderItem.items) && _vm.orderItem.items.length) + " items) ")]) : _vm._e(), _c('h4', [_vm._v("₹ " + _vm._s(_vm.orderItem.orderAmount))])])]), Array.isArray(_vm.orderItem.items) && _vm.orderItem.items.length > 0 ? _vm._l(_vm.listSlice, function (item, index) {
    return _c('ul', {
      key: index,
      staticClass: "list-unstyled"
    }, [_vm.$options.components['items-order-item'] ? [_c('items-order-item', {
      attrs: {
        "itemData": item
      }
    })] : _vm._e()], 2);
  }) : _vm._e(), Array.isArray(_vm.orderItem.items) && _vm.orderItem.items.length > 2 ? _c('div', {
    staticClass: "items-more clearfix text-center pb-2"
  }, [Array.isArray(_vm.listSlice) && _vm.listSlice.length == 2 ? _c('b-link', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.showMore
    }
  }, [_vm._v("Show More")]) : _vm._e(), Array.isArray(_vm.listSlice) && _vm.listSlice.length > 2 ? _c('b-link', {
    on: {
      "click": _vm.showLess
    }
  }, [_vm._v("Show Less")]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "text-center mt-4"
  }, [_c('b-button', {
    staticClass: "mr-3 need-helpbtn",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.needHelpClick(_vm.orderItem.orderId);
      }
    }
  }, [_vm._v("Need Help")]), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.viewDetailsClick(_vm.orderItem);
      }
    }
  }, [_vm._v("View Details")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }