<template>
  <div class="order-maindiv">
    <div class="d-flex justify-content-between mb-4">
      <div>
        <h6>#{{ orderItem.orderId }}</h6>
        <div class="d-flex">
          <b-link
            href="#"
            v-if="orderItem.orderStatus === 'Created'"
            v-bind:class="
              orderItem.orderStatus === 'Created' ? 'xsbutton' : 'created'
            "
            >{{ orderItem.orderStatus }}</b-link
          >
          <b-link
            href="#"
            v-if="orderItem.orderStatus === 'Confirmed'"
            v-bind:class="
              orderItem.orderStatus === 'Confirmed' ? 'delivered' : 'created'
            "
            >{{ orderItem.orderStatus }}</b-link
          >
          <b-link
            href="#"
            v-if="orderItem.orderStatus === 'Cancelled'"
            v-bind:class="
              orderItem.orderStatus === 'Cancelled'
                ? 'cancelled-button'
                : 'xsbutton'
            "
            >{{ orderItem.orderStatus }}</b-link
          >
          <b-link
            href="#"
            v-if="orderItem.orderStatus === 'Dispatched'"
            v-bind:class="
              orderItem.orderStatus === 'Dispatched' ? 'xs-button' : 'created'
            "
            >{{ orderItem.orderStatus }}</b-link
          >
          <b-link
            href="#"
            v-if="orderItem.orderStatus === 'Delivered'"
            v-bind:class="
              orderItem.orderStatus === 'Delivered'
                ? 'delivered'
                : 'cancelled-button'
            "
            >{{ orderItem.orderStatus }}</b-link
          >
          <b-link
            href="#"
            v-if="orderItem.orderStatus === 'Returned'"
            v-bind:class="
              orderItem.orderStatus === 'Returned'
                ? 'returned'
                : 'cancelled-button'
            "
            >{{ orderItem.orderStatus }}</b-link
          >
          <!-- <p v-bind:style="{ color: '#07ac04' }" class="m-0 ml-3">
            Arriving By 15 May
          </p> -->
        </div>
      </div>
      <div>
        <p
          v-bind:style="{ color: '#58585b' }"
          class="m-0"
          v-if="orderItem.type != 'IN-STORE'"
        >
          Order Total ({{
            Array.isArray(orderItem.items) && orderItem.items.length
          }}
          items)
        </p>
        <h4>&#x20B9; {{ orderItem.orderAmount }}</h4>
      </div>
    </div>
    <template
      v-if="Array.isArray(orderItem.items) && orderItem.items.length > 0"
    >
      <ul v-for="(item, index) in listSlice" :key="index" class="list-unstyled">
        <template v-if="$options.components['items-order-item']">
          <items-order-item :itemData="item" />
        </template>
      </ul>
    </template>
    <div
      v-if="Array.isArray(orderItem.items) && orderItem.items.length > 2"
      class="items-more clearfix text-center pb-2"
    >
      <b-link
        v-if="Array.isArray(listSlice) && listSlice.length == 2"
        @click="showMore"
        href="#"
        class=""
        >Show More</b-link
      >
      <b-link
        v-if="Array.isArray(listSlice) && listSlice.length > 2"
        @click="showLess"
        class=""
        >Show Less</b-link
      >
    </div>
    <div class="text-center mt-4">
      <b-button
        @click="needHelpClick(orderItem.orderId)"
        variant="outline-primary"
        class="mr-3 need-helpbtn"
        >Need Help</b-button
      >
      <b-button @click="viewDetailsClick(orderItem)" variant="primary"
        >View Details</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "order",
  data() {
    return {
      listSlice: [],
    };
  },
  methods: {
    showLess() {
      this.listSlice = this.orderItem.items.slice(0, 2);
    },
    showMore() {
      this.listSlice = this.orderItem.items;
    },
    needHelpClick(orderId) {
      this.$store.dispatch("profile/getOrderDetailsData", orderId);
      this.$router.push({
        name: "need-help-orderId",
        params: { orderId: orderId },
      });
    },
    viewDetailsClick(e) {
      this.$store.dispatch("profile/getOrderDetailsData", e);
      this.$router.push("/order-details");
    },
  },
  mounted() {
    if (
      Array.isArray(this.orderItem.items) &&
      this.orderItem.items.length > 2
    ) {
      this.listSlice = this.orderItem.items.slice(0, 2);
    } else {
      this.listSlice = this.orderItem.items;
    }
  },
  props: {
    orderItem: {
      default: () => {},
      type: Object,
    },
  },
};
</script>

<style scoped>
.need-helpbtn {
  color: #272727;
}
.need-helpbtn:hover {
  color: #fff;
}

.order-maindiv {
  background: #f8f8f8;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  margin-right: 15px;
  width: 980px;
  margin-left: 100px;
  border: 1px solid #efefef;
}
.cancelled-button {
  background: #c83500;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.xsbutton {
  background: #272727;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.xs-button {
  background: #021459;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.created {
  text-decoration: none;
  color: #fff;
  background: #07ac04;
}
.delivered {
  text-decoration: none;
  color: #fff;
  padding: 3px;
  background: #07ac04;
}
.returned {
  text-decoration: none;
  color: #fff;
  padding: 3px;
  background: #03a9f4;
}
.items-more a {
  color: #ff6a2e;
  border: 1px solid #ff6a2e;
  border-radius: 40px;
  padding: 2px 10px 3px 10px;
  position: relative;
  background: #f8f8f8;
}
.items-more a:hover {
  text-decoration: none;
  background: #ff6a2e;
  color: #fff !important;
}
.items-more {
  position: relative;
}
.items-more:before {
  position: absolute;
  height: 1px;
  width: 100%;
  background: #e9e9e9;
  content: "";
  left: 0;
  right: 0;
  top: 12px;
}
.cancel-dv {
  background: #cc0000 !important;
}
</style>
